import React, { useEffect, useState } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import Header from "./components/header/Header";
import Intro from "./components/intro/Intro";
import CrossPlatform from "./components/crossPlatform/CrossPlatform";
import Cases from "./components/cases/Cases";
import Clients from "./components/clients/Clients";
import Form from "./components/form/Form";
import Footer from "./components/footer/Footer";
import OurAccounts from "./components/ourAccounts/OurAccounts";

function App() {
  const [theme, setTheme] = useState(false);

  useEffect(() => {
    Aos.init();
  }, []);

  useEffect(() => {
    if (get_cookie("theme")) {
      setTheme(true);
      document.querySelector("body").classList.add("dark");
    }
    console.log(get_cookie("theme"));
  }, []);

  const onToggleTheme = (e) => {
    const value = e.target.checked;
    const body = document.querySelector("body");

    setTheme((theme) => value);
    const date = new Date(Date.now() + 96400e5).toUTCString();

    if (value) {
      body.classList.add("dark");
      document.cookie = `theme='dark'; expires=` + date;
    } else {
      body.classList.remove("dark");
      delete_cookie("theme");
    }
  };

  const delete_cookie = (cookie_name) => {
    const cookie_date = new Date();
    cookie_date.setTime(cookie_date.getTime() - 1);
    document.cookie = cookie_name += "=; expires=" + cookie_date;
  };

  const get_cookie = (cookie_name) => {
    const results = document.cookie.match(
      "(^|;) ?" + cookie_name + "=([^;]*)(;|$)"
    );

    if (results) return unescape(results[2]);
    else return null;
  };

  return (
    <div className="app">
      <Header theme={theme} onToggleTheme={onToggleTheme} />
      <Intro />
      <CrossPlatform />
      <Cases />
      {/* <Clients/> */}
      <Form />
      <OurAccounts />
      <Footer theme={theme} />
    </div>
  );
}

export default App;
