import React, {useEffect, useState} from 'react';
import style from './intro.scss';
import {Tween, Timeline} from 'react-gsap';
import {Controller, Scene} from 'react-scrollmagic';
import ButtonArrow from '../../UI/ButtonArrow/ButtonArrow';

function Intro () {
  const [activeIntro, setActiveIntro] = useState(Math.ceil(Math.random() * 2));
  const [colors, setColors] = useState([
    'linear-gradient(198.68deg, #FFBFCB 27.22%, rgba(160, 255, 170, 0.63) 86.48%)',
    'linear-gradient(198.68deg, #DA9DA8 27.22%, rgba(157, 214, 218, 0.63) 86.48%)',
    'radial-gradient(78.04% 78.04% at 50% 50%, rgba(145, 209, 255, 0.4) 0%, rgba(254, 177, 255, 0) 100%)',
    'radial-gradient(37.03% 37.03% at 50.06% 40.01%, rgba(234, 199, 255, 0.46) 0%, rgba(237, 93, 239, 0.05) 100%)',
    'linear-gradient(198.68deg, #BFE0FF 27.22%, rgba(208, 160, 255, 0.63) 86.48%)',
    'linear-gradient(198.68deg, #DAC29D 27.22%, rgba(255, 238, 219, 0.63) 86.48%)',
    'linear-gradient(180deg, rgba(86, 245, 188, 0.219) 42.03%, rgba(245, 113, 86, 0) 172.34%)',
    'radial-gradient(37.03% 37.03% at 50.06% 40.01%, rgba(199, 255, 221, 0.46) 0%, rgba(66, 54, 205, 0.05) 100%)',
    'linear-gradient(198.68deg, #EFFFC2 27.22%, rgba(255, 238, 219, 0.63) 86.48%)',
    'radial-gradient(37.03% 37.03% at 50.06% 40.01%, rgba(219, 190, 255, 0.46) 0%, rgba(178, 173, 246, 0.05) 100%)',
    'radial-gradient(78.04% 78.04% at 50% 50%, #91D1FF 0%, rgba(253, 97, 255, 0) 100%)'
  ]);
  const [balls, setBalls] = useState(Array.from(Array(5)));
  const [activeColor, setActiveColor] = useState(Array(colors.length).fill().map((e, i) => i + 1));

  useEffect(() => {
    const timer = setInterval(() => {
      const array = Array.from({length: 11}, (v, i) => i + 1).sort((a, b) => 0.5 - Math.random()).slice(0, 11);
      setActiveColor(array);
    }, 5000);
    return () => clearInterval(timer);
  });

  const renderCircle = () => {
    const height = window.innerHeight
        || document.documentElement.clientHeight
        || document.body.clientHeight;

    return Array.from(Array(10)).map((item, index) => {
      const animate = () => {
        const gSap = {from: 0, to: 0, xFrom: index * 9, xTo: index * 18};

        if (index === 0) {
          gSap.from = 3;
          gSap.to = 6;
          gSap.xTo = 0;
        } else if (index === 1) {
          gSap.from = 0;
          gSap.to = 4;
        } else if (index === 2) {
          gSap.from = 0;
          gSap.to = 10;
        } else if (index === 3) {
          gSap.from = 0;
          gSap.to = 10;
        } else if (index === 4) {
          gSap.from = 0;
          gSap.to = 5;
        } else if (index === 5) {
          gSap.from = 2;
          gSap.to = 0;
        } else if (index === 6) {
          gSap.from = 3;
          gSap.to = 0;
        } else if (index === 7) {
          gSap.from = 4;
          gSap.to = 0;
        } else if (index === 8) {
          gSap.from = 5;
          gSap.to = 0;
        } else if (index === 9) {
          gSap.from = 12;
          gSap.to = 0;
        }

        return gSap;
      };

      return (
          <div className="intro__circle" key={index}>
            <Controller>
              <Scene duration={height} triggerElement="#trigger" triggerHook={0}>
                {(progress) => (
                    <Tween
                        from={{
                          css: {
                            filter: `blur(${animate().from}px)`,
                            transform: `translateX(${animate().xFrom}vh)`,
                          }, ease: 'sine.out',
                        }}
                        to={{
                          css: {
                            filter: `blur(${animate().to}px)`,
                            transform: `translateX(${animate().xTo}vh)`,
                          }, ease: 'sine.out',
                        }}
                        totalProgress={progress}
                        paused>
                      <div className="tween">Pin Test</div>
                    </Tween>
                )}
              </Scene>
            </Controller>
          </div>
      );
    });
  };

  const renderBalls = () => {
    const opacityAnimate = (index, indexColor) => {
      let opacity = 0;

      if (index === indexColor) {
        opacity = 1;
      }

      return opacity;
    };
    return (
        <div className="balls">
          {balls.map((item, i) => {
            return (
                <span key={i} className="balls__item">
                    {colors.map((bg, index) => (
                        <span
                            key={index}
                            style={{
                              background: bg,
                              opacity: opacityAnimate(index, activeColor[i]),
                            }}
                        >ball</span>
                    ))}
                  </span>
            );
          })}
        </div>
    );
  };

  const scrollRequest = () => {
    const element = document.getElementById('form');
    const headerOffset = 100;
    const elementPosition = element.getBoundingClientRect().top;
    const offsetPosition = elementPosition + window.pageYOffset - headerOffset;
    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth"
    });
  }
  
  return (
      <div className="intro" id="trigger">
        {activeIntro === 1 ? renderCircle() : renderBalls()}

        <div className="intro__wrap">
          <h1 className="intro__title">
            We are here to help <br/> you in providing<br/> perfect&nbsp;
            <span className="intro__title_gradient">cross-platform <br/> mobile solutions.</span>
          </h1>
        </div>

        <div className="intro__buttons">
          <div className="container-fluid">
            <div className="row d-flex align-items-center justify-content-between">
              <div className="col-auto">
                <ul className="intro__lang">
                  {/* <li className="intro__lang_active">en</li>
                  <li>ru</li> */}
                </ul>
              </div>
              <div className="col-auto">
                <ButtonArrow onClick={() => scrollRequest()}><span>Leave <b>a request</b></span></ButtonArrow>
              </div>
            </div>
          </div>
        </div>
      </div>
  );
}

export default Intro;
