import React from "react";
import logo from "../../assets/img/logo.svg";
import "./footer.scss";
import Logo from "../../UI/Logo/Logo";

function Footer({ theme }) {
  return (
    <div className="footer">
      <div className="container-fluid">
        <div className="row justify-content-between align-items-center">
          <div className="col-sm-auto">
            <div className="footer__logo">
              <Logo theme={theme} />
            </div>
          </div>
          <div className="col-sm-auto">
            <a
              className="footer__link"
              href="mailto:info@wearethesatellite.com"
            >
              info@wearethesatellite.com
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
