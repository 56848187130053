import React from "react";
import "./logo.scss";

const Logo = ({ theme }) => {
  return (
    <svg
      version="1.1"
      height="100%"
      viewBox="0 0 600 80"
      width="100%"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <linearGradient
          gradientTransform="matrix(1 0 0 1 -75.4609 -219.545)"
          gradientUnits="userSpaceOnUse"
          id="LinearGradient"
          x1="145.874"
          x2="145.261"
          y1="223.254"
          y2="279.014"
        >
          <stop offset="0.0808119" stop-color="#50cdd1" />
          <stop offset="0.9993" stop-color="#3890c2" />
        </linearGradient>
      </defs>
      <g id="Untitled">
        <g opacity="1">
          <path
            d="M439.339 56.7551C439.339 56.9551 439.239 56.9551 439.139 56.9551L432.939 56.9551C432.739 56.9551 432.739 56.8551 432.739 56.7551L432.739 31.1551L415.739 31.1551L415.739 24.4551L457.539 24.4551L453.539 31.1551L439.439 31.1551L439.439 56.7551L439.339 56.7551Z"
            fill="#282828"
            fill-rule="nonzero"
            opacity="1"
            stroke="none"
          />
          <path
            d="M495.839 42.1551C496.039 42.1551 496.039 42.0551 496.039 41.9551L496.039 35.8551C496.039 35.1551 495.839 33.9551 495.339 32.3551C492.839 27.0551 489.339 24.4551 484.939 24.4551L467.439 24.4551C465.739 24.4551 464.039 24.9551 462.339 25.8551C458.239 28.6551 456.139 32.3551 456.139 36.9551L456.139 44.3551C456.139 46.4551 456.739 48.5551 457.839 50.7551C460.539 54.8551 463.539 56.8551 466.939 56.8551L485.439 56.8551C488.239 56.8551 490.839 55.4551 493.239 52.5551C494.739 50.8551 495.739 48.6551 496.139 46.0551C496.139 45.8551 496.039 45.8551 495.939 45.7551L489.839 45.7551C489.739 45.7551 489.639 45.8551 489.539 46.0551C488.639 48.7551 487.139 50.0551 484.939 50.0551L466.939 50.0551C464.339 49.0551 463.039 47.5551 463.039 45.7551L463.039 42.0551L495.839 42.0551L495.839 42.1551ZM467.939 31.1551L484.539 31.1551C487.139 31.1551 488.839 32.6551 489.539 35.5551L462.939 35.5551C463.539 32.5551 465.239 31.1551 467.939 31.1551Z"
            fill="#282828"
            fill-rule="nonzero"
            opacity="1"
            stroke="none"
          />
          <path
            d="M152.639 31.2551C150.639 31.2551 149.339 31.9551 148.839 33.2551C148.839 33.3551 148.739 33.6551 148.539 34.2551C148.939 36.3551 149.939 37.3551 151.439 37.3551L172.239 37.3551C177.439 37.3551 180.839 39.9551 182.439 45.0551C182.639 45.5551 182.639 46.2551 182.639 47.0551L182.639 47.1551C182.639 51.5551 180.439 54.6551 176.039 56.5551C175.139 56.8551 174.339 57.0551 173.539 57.0551L142.639 57.0551L142.639 50.3551L173.239 50.3551C175.439 50.0551 176.539 49.1551 176.539 47.4551L176.539 46.9551C176.539 45.4551 175.439 44.4551 173.339 44.2551L152.339 44.2551C147.839 44.2551 144.639 41.7551 142.839 36.7551C142.639 36.1551 142.639 35.5551 142.639 34.9551L142.639 34.0551C142.639 29.8551 145.039 26.7551 149.839 24.8551C150.739 24.6551 151.439 24.6551 151.839 24.6551L187.739 24.6551L183.739 31.3551L152.639 31.3551L152.639 31.2551Z"
            fill="#282828"
            fill-rule="nonzero"
            opacity="1"
            stroke="none"
          />
          <path
            d="M227.539 56.8551C227.539 57.0551 227.439 57.0551 227.339 57.0551L221.139 57.0551C220.939 57.0551 220.839 56.9551 220.839 56.8551L220.839 56.0551L220.739 56.0551C219.239 56.7551 217.939 57.1551 216.739 57.1551L198.239 57.1551C194.839 57.1551 191.839 55.1551 189.139 51.0551C188.039 48.9551 187.439 46.7551 187.439 44.6551L187.439 37.2551C187.439 32.5551 189.539 28.8551 193.639 26.1551C195.239 25.1551 196.939 24.7551 198.739 24.7551L216.239 24.7551C217.939 24.7551 219.439 25.1551 220.839 25.8551L220.839 24.7551L227.539 24.7551C227.539 24.7551 227.539 56.8551 227.539 56.8551ZM220.839 35.9551C220.839 33.6551 219.439 32.1551 216.739 31.3551C216.639 31.2551 216.339 31.2551 215.839 31.2551L199.239 31.2551C196.539 31.2551 194.839 32.7551 194.239 35.6551L194.239 46.0551C194.239 47.9551 195.539 49.3551 198.139 50.3551L216.139 50.3551C218.439 50.3551 219.939 49.0551 220.739 46.3551L220.739 35.9551L220.839 35.9551Z"
            fill="#282828"
            fill-rule="nonzero"
            opacity="1"
            stroke="none"
          />
          <path
            d="M257.039 56.8551C257.039 57.0551 256.939 57.0551 256.839 57.0551L250.639 57.0551C250.439 57.0551 250.439 56.9551 250.439 56.8551L250.439 31.2551L233.439 31.2551L233.439 24.5551L275.239 24.5551L271.239 31.2551L257.139 31.2551L257.139 56.8551L257.039 56.8551Z"
            fill="#282828"
            fill-rule="nonzero"
            opacity="1"
            stroke="none"
          />
          <path
            d="M313.539 42.3551C313.739 42.3551 313.739 42.2551 313.739 42.1551L313.739 36.0551C313.739 35.3551 313.539 34.1551 313.039 32.5551C310.539 27.2551 307.039 24.6551 302.639 24.6551L285.139 24.6551C283.439 24.6551 281.739 25.1551 280.039 26.0551C275.939 28.8551 273.839 32.5551 273.839 37.1551L273.839 44.5551C273.839 46.6551 274.439 48.7551 275.539 50.9551C278.239 55.0551 281.239 57.0551 284.639 57.0551L303.139 57.0551C305.939 57.0551 308.539 55.6551 310.939 52.7551C312.439 51.0551 313.439 48.8551 313.839 46.2551C313.839 46.0551 313.739 46.0551 313.639 45.9551L307.539 45.9551C307.439 45.9551 307.339 46.0551 307.239 46.2551C306.339 48.9551 304.839 50.2551 302.639 50.2551L284.639 50.2551C282.039 49.2551 280.739 47.7551 280.739 45.9551L280.739 42.2551L313.539 42.2551L313.539 42.3551ZM285.539 31.2551L302.139 31.2551C304.739 31.2551 306.439 32.7551 307.139 35.6551L280.539 35.6551C281.239 32.7551 282.939 31.2551 285.539 31.2551Z"
            fill="#282828"
            fill-rule="nonzero"
            opacity="1"
            stroke="none"
          />
          <path
            d="M325.539 45.7551C325.539 46.0551 325.539 46.3551 325.639 46.7551C326.539 49.2551 328.239 50.4551 330.639 50.4551L354.739 50.4551L354.739 57.1551L330.139 57.1551C329.239 57.1551 327.939 56.9551 326.239 56.4551C321.239 53.6551 318.739 49.7551 318.739 44.6551L318.739 24.6551L325.439 24.6551L325.439 45.7551L325.539 45.7551Z"
            fill="#282828"
            fill-rule="nonzero"
            opacity="1"
            stroke="none"
          />
          <path
            d="M364.739 45.7551C364.739 46.0551 364.739 46.3551 364.839 46.7551C365.739 49.2551 367.439 50.4551 369.839 50.4551L398.039 50.4551L398.039 57.1551L369.339 57.1551C368.439 57.1551 367.139 56.9551 365.439 56.4551C360.439 53.6551 357.939 49.7551 357.939 44.6551L357.939 24.6551L364.639 24.6551L364.639 45.7551L364.739 45.7551Z"
            fill="#282828"
            fill-rule="nonzero"
            opacity="1"
            stroke="none"
          />
          <path
            d="M410.339 24.5551C410.539 24.5551 410.639 24.6551 410.639 24.7551L410.639 56.7551C410.639 56.9551 410.539 56.9551 410.339 56.9551L404.239 56.9551C404.039 56.9551 404.039 56.8551 404.039 56.7551L404.039 24.7551C404.139 24.5551 404.139 24.5551 404.239 24.5551L410.339 24.5551Z"
            fill="#282828"
            fill-rule="nonzero"
            opacity="1"
            stroke="none"
          />
        </g>
        <path
          d="M74.6391 65.2551L74.6391 38.0551L93.5391 26.6551L93.5391 45.4551L131.139 22.1551L101.339 4.45509L101.339 13.2551L116.139 22.2551L101.339 31.4551L101.339 21.9551L70.9391 3.55509L40.5391 21.9551L67.0391 37.9551L67.0391 65.2551L48.1391 54.5551L48.1391 45.3551L40.3391 41.0551L9.03906 59.3551L40.3391 77.1551L40.3391 68.2551L24.5391 59.3551L40.3391 50.1551L40.3391 59.2551L70.7391 76.4551L101.339 59.6551L101.339 50.8551L74.6391 65.2551ZM70.8391 12.5551L86.2391 21.8551L70.8391 31.1551L55.4391 21.8551L70.8391 12.5551Z"
          fill="url(#LinearGradient)"
          fill-rule="nonzero"
          opacity="1"
          stroke="none"
        />
      </g>
    </svg>
  );
};

export default Logo;
