import React, { useEffect, useState } from "react";
import Section from "../section/Section";
import "./form.scss";
import ButtonArrow from "../../UI/ButtonArrow/ButtonArrow";
import axios from "../axios/axios.js";
var _csrfToken = "";

function Form(props) {
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");

  const phoneError = "";

  async function handleClick(e) {
    e.preventDefault();
    const formData = { name: name, phone: phone, email: email };

    if (validate(formData)) {
      const response = await axios.get("/csrf/");
      _csrfToken = response.data.csrfToken;
      console.log(_csrfToken);
      const headers = {
        "X-CSRFToken": _csrfToken,
      };
      axios
        .post("send_email/", formData, { headers: headers })
        .then(function (results) {
          setName("");
          setPhone("");
          setEmail("");
          console.log("The link was clicked.");
          alert("Request sent successfully!");
        });
    }
  }

  function validate(formData) {
    let errors = {};
    let isValid = true;

    /* if (typeof formData["email"] !== "undefined") {
          
        var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
        if (!pattern.test(formData["email"])) {
          isValid = false;
          errors["email"] = "Please enter valid email address.";
        }
      }*/

    if (!formData["phone"]) {
      isValid = false;
      errors["phone"] = "Please enter your phone number.";
    }

    if (typeof formData["phone"] !== "undefined") {
      var pattern = new RegExp(/^[0-9\b]+$/);
      if (!pattern.test(formData["phone"])) {
        isValid = false;
        errors["phone"] = "Please enter only number.";
      } else if (formData["phone"].length != 10) {
        isValid = false;
        errors["phone"] = "Please enter valid phone number.";
      }
    }

    if (errors["phone"]) {
      alert(errors["phone"]);
    }

    /* if (errors["email"]) {
		  alert(errors["email"]);
	  }*/

    return isValid;
  }

  return (
    <Section id="form">
      <div className="row">
        <div className="col-lg-6">
          <h2 className="section__title">Want to work with us?</h2>
        </div>
        <div className="col-lg-5">
          <form className="form">
            <label className="form__label">
              <span className="form__title">Your name</span>
              <input
                className="form__input"
                required={true}
                placeholder="Enter your name"
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </label>
            <label className="form__label">
              <span className="form__title">Your phone</span>
              <input
                className="form__input"
                required={true}
                placeholder="Enter your phone number"
                type="phone"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
            </label>
            <label className="form__label">
              <span className="form__title">Your email</span>
              <input
                className="form__input"
                required={true}
                placeholder="Enter your email"
                type="mail"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </label>
            <div className="form__button">
              <ButtonArrow onClick={handleClick}>
                <span>
                  Send <b>a request</b>
                </span>
              </ButtonArrow>
            </div>
            {/* <a className='form__link' href="mailto:hi@wearethesatellite.com">hi@wearethesatellite.com</a> */}
          </form>
        </div>
      </div>
    </Section>
  );
}

export default Form;
