import React, { useState } from "react";
import style from "./header.scss";
import logo from "../../assets/img/logo.svg";
import lightIcon from "../../assets/img/light.svg";
import darkIcon from "../../assets/img/dark.svg";
import "./toggle-theme.scss";
import Logo from "../../UI/Logo/Logo";

function Header({ theme, onToggleTheme }) {
  return (
    <div className="header">
      <div className="container-fluid">
        <div className="row align-items-center justify-content-between">
          <div className="col-auto">
            <div className="header__logo">
              <Logo theme={theme} />
            </div>
          </div>
          <div className={`col-auto menu`}>
            <ul className="header__menu">
              {/* <li><a href="#">cases</a></li> */}
            </ul>
          </div>
          <div className="col-auto">
            <a
              className="header__mail"
              href="mailto:info@wearethesatellite.com"
            >
              info@wearethesatellite.com
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
