import React from 'react';

const Arrow = () => {
  return (
      <svg width="90" height="24" viewBox="0 0 90 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M2 10.5C1.17157 10.5 0.5 11.1716 0.5 12C0.5 12.8284 1.17157 13.5 2 13.5V10.5ZM89.0607 13.0607C89.6464 12.4749 89.6464 11.5251 89.0607 10.9393L79.5147 1.3934C78.9289 0.807611 77.9792 0.807611 77.3934 1.3934C76.8076 1.97919 76.8076 2.92893 77.3934 3.51472L85.8787 12L77.3934 20.4853C76.8076 21.0711 76.8076 22.0208 77.3934 22.6066C77.9792 23.1924 78.9289 23.1924 79.5147 22.6066L89.0607 13.0607ZM2 13.5H88V10.5H2V13.5Z"
            fill="var(--c-color)"/>
      </svg>
  );
};

export default Arrow;
