import React, {useEffect, useState} from 'react';
import Section from '../section/Section';
import {Swiper, SwiperSlide} from 'swiper/react';
import SwiperCore, {Navigation} from 'swiper';
import 'swiper/swiper-bundle.css';
import 'swiper/swiper.min.css';
import './cases.scss';
import logoCase from '../../assets/img/case-logo.png';
import slideItem from '../../assets/img/slide-item.png';
import arrowLeft from '../../assets/img/arrow-left.svg';
import arrowRight from '../../assets/img/arrow-right.svg';
import axios from '../axios/axios.js'

function Cases () {
	const rows = [];
	const [casesJSON, setCasesJSON] = React.useState({});
		React.useEffect(() => {
		 axios.get("/cases/").then(response => {
		   setCasesJSON(response);
		 }).catch(error => {});

		}, [])
		
		for(var i in casesJSON.data) {
			
			var tt = casesJSON.data[i];
			rows.push({
			  id: tt.id,
			  platform: tt.cross_platform,
			  title: tt.title,
			  date: tt.created,
			  description: tt.description,
			  logoWeb: tt.site_icon,
			  linkWeb: tt.site_href,
			  linkNameWeb: tt.site_name,
			  logoAndroid: tt.android_icon,
			  linkAndroid: tt.android_href,
			  linkNameAndroid: tt.android_name,
			  logoIos: tt.ios_icon,
			  linkIos: tt.ios_href,
			  linkNameIos: tt.ios_name,
			  slides: tt.case_image_list
			});
		}
		
		const cases = rows
		const setCases = useState(rows)
  SwiperCore.use([Navigation]);

  const renderCases = () => {
    return cases.map(item => {
      return (
          <div data-aos='fade-up' className="case" key={item.id}>
            <div className="row">
              <div className="col-lg-6">
                <div className="case__content">
                  <div className="case__platform">
                    {item.platform.map((platform, index) => (
                        <span key={index}
                              style={{backgroundColor: platform.title_color}}>{platform.title}
                      </span>
                    ))}
                  </div>
                  <h3 className="case__title">{item.title}</h3>
                  <p className="case__date">{item.date}</p>
                  <p className="case__description">{item.description}</p>
                  <div className="case__footer">
                    <div className="logo">
                    <img
                      src={item.logoWeb.replace("cases/", "")}
                      alt={item.title}
                    />
                      <a href={item.linkWeb}>{item.linkNameWeb}</a>
                    </div>
                    <div className="arrows">
                      <button className={`prev-${item.id}`}><img src={arrowLeft.replace("cases/", "")} alt=""/></button>
                      <button className={`next-${item.id}`}><img src={arrowRight.replace("cases/", "")} alt=""/></button>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-6">
                <div className="case__slider">
                  <Swiper
                      navigation={{
                        prevEl: `.prev-${item.id}`,
                        nextEl: `.next-${item.id}`,
                      }}
                      spaceBetween={20}
                      slidesPerView={2}
                      centeredSlides={true}
                  >
                    {item.slides.map((slide, index) => (
                        <SwiperSlide key={index}>
                          <img src={slide.images.replace("cases/", "")} alt=""/>
                        </SwiperSlide>
                    ))}
                  </Swiper>
                </div>
              </div>
            </div>
          </div>
      );
    });
  };

  return (
      <Section>
        <div className="row">
          <div className="col-md-6">
            <h2 className="section__title">Our cases</h2>
          </div>
        </div>

        <div className="case_container">
          {renderCases()}
        </div>
      </Section>
  );
}

export default Cases;
