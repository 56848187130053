import React from 'react';
import './section.scss';

function Section (props) {
  return (
      <section className="section" {...props}>
        <div className="container-fluid">
          {props.children}
        </div>
      </section>
  );
}

export default Section;
