import React from 'react';
import Faq from 'react-faq-component';
import Section from '../section/Section';
import style from './cross_platform.scss';
import './faq.scss';
import arrow from '../../assets/img/arrow-link.svg'
import axios from '../axios/axios.js'

function CrossPlatform(props) {
    const rows = [];

    const titleFaq = (title, tags, color, text) => {
        return (
            <>
                <div className="faq__title">
                    <p className="faq__title_title">{title}</p>
                    <div className="faq__title_right">
                        {tags.map((tags, index) => (
                            <span className="faq__title_tag" key={index}
                                  style={{backgroundColor: color}}>{tags.name}
                            </span>
                        ))}
                    </div>
                </div>
                <p className="faq__text">{text}</p>
            </>
        );
    };

    const content = (caseList) => {
        return (
            <div className='content'>
                {caseList.map((caseList, index) => (
                    <a className='content__item' href={caseList.site_href}>
                        <img src={arrow.replace("technologies/", "")} alt={caseList.title}/>
                        {caseList.title}
                    </a>
                ))}
            </div>
        )
    }
    const data = {rows: [],};

    const [crossPlatformJSON, setCrossPlatformJSON] = React.useState({});
    React.useEffect(() => {
        axios.get("/technologies/").then(response => {
            setCrossPlatformJSON(response);
        }).catch(error => {
            console.log(error)
        });

    }, [])

    for (var i in crossPlatformJSON.data) {

        var tt = crossPlatformJSON.data[i];
        data.rows.push({
            title: titleFaq(tt.title, tt.tehnologi_tag, tt.title_color, tt.text),
            content: content(tt.case_list)
        });
    }

    const styles = {
        bgColor: 'transparent',
        rowContentPaddingTop: '10px',
        rowContentPaddingBottom: '40px',
    };

    const config = {
        arrowIcon: 'V',
    };

    return (
        <Section className='section'>
            <div className="row justify-content-between">
                <div className="col-xl-4">
                    <h2 className="section__title">Technologies</h2>
                    <p className="section__descriptor">We use React Native and Xamarin as the most convenient methods
                        for
                        cross-platform development. But also we
                        can always write an application using native methods.</p>
                </div>

                <div className="col-xl-7">
                    <div id="faq" className="faq">
                        <Faq
                            data={data}
                            styles={styles}
                            config={config}
                        />
                    </div>
                </div>
            </div>
        </Section>
    );
}

export default CrossPlatform;
