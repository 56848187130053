import React from 'react';
import Section from "../section/Section";
import "./OurAccounts.scss"
import account1 from "../../assets/img/account1.svg"
import account2 from "../../assets/img/account2.svg"
import account3 from "../../assets/img/account3.svg"
import arrow from "../../assets/img/arrow-black.svg"
import axios from '../axios/axios.js'

const OurAccounts = () => {
	const rowsAccount = [];
	const [ourAccountJSON, setOurAccountJSON] = React.useState({});
		React.useEffect(() => {
		 axios.get("/our_account/").then(response => {
		   setOurAccountJSON(response);
		 }).catch(error => {});

		}, [])
		
		
		for(let i in ourAccountJSON.data) {
			
			const tt = ourAccountJSON.data[i];
			rowsAccount.push(tt);
		}
    return (
        <Section className="account section">
            <div className="row d-flex align-items-center">
                <div className="col-lg-3">
                    <h2 className="account__title">Our links</h2>
                </div>
                <div className="col-lg-9">
                    <div className="row">
						{rowsAccount.map((rowsAccount, index) => (
						<div className="col-sm-4">
                            <a href={rowsAccount.href} className="account__item">
                                <img src={rowsAccount.icon.replace("our_account/", "")} alt={rowsAccount.name}/>
                                <img className="account__item--arrow" src={arrow} alt=""/>
                            </a>
                        </div>
						))}
                    </div>
                </div>
            </div>
        </Section>
    );
};

export default OurAccounts;