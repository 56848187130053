import React from 'react';
import './buttonArrow.scss';
import Arrow from './Arrow';

function ButtonArrow (props) {
  return (
      <button className="button" {...props}>
        {props.children}
        <span className="button__arrow">
          <Arrow/>
        </span>
      </button>
  );
}

export default ButtonArrow;
